import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import SportsMenu from "../../components/sportsMenu";
import LigSlider from "../../components/ligSlider";
import OddSection from "./components/OddSection";
import { useCurrentLanguage } from "../../misc";
import {
  addPopularOddToCollection,
  addLigItemToCollection,
} from "../../feature/appAction";
import { ReducerContext } from "../../context/ReducerContext";
import { useNavigate, useParams } from "react-router-dom";
import {CalendarIcon} from "../../assets/svgs";

import styles from './free-tips.module.scss';
import { sortLeaguesData } from "../../utils/listUtils";

/**
 * HomePage - component it's first page which is show app started.
 */

const FreeTips = () => {
  const {
    fireBaseAllLeaguesDataBase,
    fireBaseAllEventsDataBase,
    fireBaseHomePageSliderLiveDataBase,
    fireBaseHomePageSliderDataBase,
  } = useContext(AppContext);
  const fixedView = {today: 'Bugün', tomorrow: 'Yarın', future: 'Gelecek'};
  const fixedViewKeys = Object.keys(fixedView);
  const { tipsCollection, dispatch } = useContext(ReducerContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const settings = useCurrentLanguage()
  const [isLoading, setIsLoading] = useState(true); // Add isLoading state
  const [isViewByLeagues, setIsViewByLeagues] = useState(() => !fixedViewKeys.includes(id));

  useEffect(() => {
    if (
      fireBaseAllLeaguesDataBase &&
      fireBaseAllEventsDataBase &&
      fireBaseHomePageSliderDataBase
      // && fireBaseHomePageSliderLiveDataBase
    ) {
      // Set isLoading to false once data is available
      setIsLoading(false);
    }
  }, [
    fireBaseAllLeaguesDataBase,
    fireBaseAllEventsDataBase,
    fireBaseHomePageSliderDataBase,
    fireBaseHomePageSliderLiveDataBase
  ]);

  const handleSelect = (item) => {
    navigate(`/free-tips/${item}`);
  };

  const handleSelectOdd = (data) => {
    addPopularOddToCollection(dispatch, data);
  };

  const handleSelectLigItem = (data) => {
    addLigItemToCollection(dispatch, data);
  };

  const filteredLigSliderData = fireBaseHomePageSliderLiveDataBase?.filter(
    (item) => item.competitionId === id
  );

  const calendarClickHandler = () => {
    setIsViewByLeagues(!isViewByLeagues);
  }

  useEffect(() => {
    if (id) {
      if (isViewByLeagues === true && fireBaseAllLeaguesDataBase !== undefined) {
        const sortedKeys = sortLeaguesData(fireBaseAllLeaguesDataBase)
        navigate(`/free-tips/${fixedViewKeys.includes(id) ? sortedKeys[0] : id}`);
      } else if (isViewByLeagues === false) {
        navigate(`/free-tips/${fixedViewKeys.includes(id) ? id : fixedViewKeys[0]}`);
      }
    }
  }, [isViewByLeagues, fireBaseAllLeaguesDataBase, id]);

  return (
    <div className="odd_section_container">
      <div className="odd_section_header">
        <h2>{settings.staticString.thisWeeksTopMatches}</h2>
      </div>
      <div className={styles.leaguesContainer}>
        <div className={styles.calendarIcon}>
          <CalendarIcon onClick={calendarClickHandler} />
        </div>
        <SportsMenu
          data={isViewByLeagues ? fireBaseAllLeaguesDataBase : fixedView}
          handleSelect={handleSelect}
          selectedItem={id}
          isFixedMenu={!isViewByLeagues}
        />
      </div>
      <LigSlider
        data={filteredLigSliderData}
        handleSelectLig={handleSelectLigItem}
        tipsCollection={tipsCollection}
        isLoading={
          filteredLigSliderData === undefined ||
          filteredLigSliderData?.length > 0
            ? false
            : true
        } // Pass the isLoading prop
        displayLiveLabel={true}
      />
      <OddSection
        data={fireBaseAllEventsDataBase}
        handleSelectOdd={handleSelectOdd}
        selectedItem={tipsCollection}
        isFixedMenu={!isViewByLeagues}
        fixedViewKeys={fixedViewKeys}
      />
    </div>
  );
};

export default FreeTips;
