import { useEffect } from 'react'

/**
 * useScroll - hooks create for dragging scroll on web
 * 
 * @param containerRef - container ref which item you want to scroll
 * @returns 
 */
const useScroll = (containerRef) => {
    useEffect(() => {
        if (!containerRef.current) return null;
        const slider = containerRef.current;
        let isDown = false;
        let startX;
        let scrollLeft;
    
        slider.addEventListener("mousedown", (e) => {
          isDown = true;
          slider.classList.add("active");
          startX = e.pageX - slider.offsetLeft;
          scrollLeft = slider.scrollLeft;
          cancelMomentumTracking();
        });
    
        slider.addEventListener("mouseleave", () => {
          isDown = false;
          slider.classList.remove("active");
        });
    
        slider.addEventListener("mouseup", () => {
          isDown = false;
          slider.classList.remove("active");
          beginMomentumTracking();
        });
    
        slider.addEventListener("mousemove", (e) => {
          if (!isDown) return;
          e.preventDefault();
          const x = e.pageX - slider.offsetLeft;
          const walk = (x - startX) * 3; //scroll-fast
          const prevScrollLeft = slider.scrollLeft;
          slider.scrollLeft = scrollLeft - walk;
          velX = slider.scrollLeft - prevScrollLeft;
        });
    
        // Momentum
        let velX = 0;
        let momentumID;
    
        slider.addEventListener("wheel", (e) => {
          cancelMomentumTracking();
        });
    
        function beginMomentumTracking() {
          cancelMomentumTracking();
          momentumID = requestAnimationFrame(momentumLoop);
        }
        function cancelMomentumTracking() {
          cancelAnimationFrame(momentumID);
        }
        function momentumLoop() {
          slider.scrollLeft += velX;
          velX *= 0.95;
          if (Math.abs(velX) > 0.5) {
            momentumID = requestAnimationFrame(momentumLoop);
          }
        }
      }, [containerRef]);
      return null
}

export default useScroll