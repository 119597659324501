const staticString = {
  akilliBahis: "AKILLI BAHIS",
  home: "Ev",
  freeTips: "Tavsiye",
  multiBet: "Çoklu Bahis",
  news: "Haberler",
  katkılarıyla: "katkılarıyla",
  popularOdds: "Popüler Oranlar",
  buildYourOwn: "Kendinizinkini İnşa Edin",
  myTipCollection: "Bahşiş Koleksiyonum",
  totalOdds: "Toplam Oran",
  totalWins: "Toplam Galibiyet",
  shareMyTipCollection: "Bahşiş Koleksiyonumu Paylaş",
  downloadMyTipCollection: "Bahşiş Koleksiyonumu İndirin",
  thisWeeksTopMatches: "Bu Haftanın En İyi Maçları",
  thisWeeksTopStories: "Bu Haftanın En Çok Okunan Haberleri",
  winningMoreThan: "Birden fazla kazanmak",
  tipCollectionIsEmpty: "Bahşiş koleksiyonu boş",
  alterSuggestions: "Önerileri Değiştir",
  loading: "Yükleniyor...",
  readMore: "Devamını oku",
  close: "Kapalı",
  noNewsAvailable: "Haber yok",
  emptyData: "Veri bulunamadı",
  noDataFound: "Seçilen lig ile eşleşme bulunamadı",
  live: "Canlı",
  matchWinner: "Maç Kazananı",
  addToCollection: "Koleksiyona ekle",
  january: "ocak",
  february: "şubat",
  march: "mart",
  april: "nisan",
  may: "mayıs",
  jun: "haziran",
  july: "temmuz",
  august: "ağustos",
  september: "eylül",
  october: "ekim",
  november: "kasım",
  december: "aralık",
};

const marketNames = {
  home: "Ev Sahibi",
  away: "Deplasman",
  draw: "Beraberlik",
};

const translations = {
  "under": "Alt",
  "over": "Ust",
  "home": "Ev Sahibi",
  "away": "Deplasman",
  "draw": "Beraberlik",
  "yes": "Evet",
  "no": "Hayır",
  "1st half": "İlk Yarı",
  "2nd half": "İkinci Yarı",
  "both teams to score": "İki Takım da Gol Atar",
  "to win and both teams to score": "Kazanır ve 2 Takım da Gol Atar",
  "away/yes": "Deplasman / Evet",
  "away/no": "Deplasman / Hayır",
  "home/yes": "Ev Sahibi / Evet",
  "home/no": "Ev Sahibi / Hayır",
  "total goals": "Toplam Gol",
  "match prices": "Maç Oranları",
};

const settings = {
  staticString,
  marketNames,
  translations,
};

export default settings;
