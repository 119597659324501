import axios from "axios";

const url = process.env.REACT_APP_YOUWIN_API;

// function use for odd button status return
export const getCurrentOddStatus = (title, line) => {
  switch (title?.toLowerCase()) {
    case 'home':
      return '1';
    case 'away':
      return '2';
    case 'draw':
      return 'X';
    case 'under':
      return `A ${line ? "+" + line : ""}`;
    case 'over':
      return `U ${line ? "-" + line : ""}`;
    case 'yes':
      return 'yes';
    case 'no':
      return 'no';
    default:
      return '';
  }
};

export const getNameValue = (name_en, settings) => {
  const marketName = name_en?.toLowerCase();
  switch (marketName) {
    case "home":
      return settings.marketNames.home;
    case "away":
      return settings.marketNames.away;
    case "draw":
      return settings.marketNames.draw;
    default:
      return null; // Or return undefined or another distinguishable value.
  }
};

export const multiBetAPI = async (stake, win) => {
  try {
    const apiUrl = `${url}/getmymultibetsjson?stake=${stake}&win=${win}`;
    const response = await axios.get(apiUrl);
    return response.data;
  } catch (error) {
    console.log("Internal Server Error", error.message);
  }
};

export const multiBetAlterSuggestionAPI = async (eventIds, multiGroupIds) => {
  try {
    const apiUrl = `${url}/GetReplaceBet?ExcludeMatches=${eventIds}&MultiGroupId=${multiGroupIds}&lang=en`;
    const response = await axios.get(apiUrl);
    return response.data;
  } catch (error) {
    console.log("Internal Server Error", error.message);
  }
};

export const multiBetReplaceSuggestionAPI = (multiGroupId, eventIds) => {
  try {
    const apiUrl = `${url}/GetReplaceBet?ExcludeMatches=${eventIds}&MultiGroupId=${multiGroupId}&lang=en`;
    return axios.get(apiUrl);
    // return response?.data?.MultibetItems[0];
  } catch (error) {
    console.log("Internal Server Error", error.message);
  }
};


export const getTranslation = (term, translations) => {
  return ( term && (typeof term != 'undefined') && (typeof translations[term.toLowerCase()] != 'undefined') ) ? translations[term.toLowerCase()] : term;
}
