const preDefinedSortForLeague = [
  "TurkiyeSuperLig",
  "EnglandPremierLeague",
  "GermanyBundesliga",
  "ItalySerieA",
  "SpainLaLiga",
];

export const sortLeaguesData = (leagues, isFixedMenu = false) => {
  if (isFixedMenu) {
    return Object.keys(leagues);
  }
  const sortedData = Object.keys(leagues ?? {})
    .filter((_key) => !preDefinedSortForLeague.includes(_key))
    .sort((a, b) => a.localeCompare(b));
  return [...preDefinedSortForLeague, ...sortedData];
};
