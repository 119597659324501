const staticString = {
  akilliBahis: "SMART BETTING",
  home: "Home",
  freeTips: "Free Tips",
  multiBet: "Multi Bet",
  news: "News",
  katkılarıyla: "katkılarıyla",
  popularOdds: "Popular Odds",
  buildYourOwn: "Build your own",
  myTipCollection: "My Tip Collection",
  totalOdds: "Total Odds",
  totalWins: "Total Wins",
  shareMyTipCollection: "Share My Tip Collection",
  downloadMyTipCollection: "Download My Tip Collection",
  thisWeeksTopMatches: "This Week's Top Matches",
  thisWeeksTopStories: "This Week's Top Stories",
  winningMoreThan: "Winning more than",
  tipCollectionIsEmpty: "Tip collection is empty",
  alterSuggestions: "Alter Suggestions",
  loading: "Loading...",
  readMore: "Read more",
  close: "Close",
  noNewsAvailable: "No news available",
  emptyData: "No data found",
  noDataFound: "No matches with the selected league found",
  live: "Live",
  matchWinner: "Match Winner",
  addToCollection: "Add to collection",
  january: "Jan",
  february: "Feb",
  march: "Mar",
  april: "Apr",
  may: "May",
  jun: "Jun",
  july: "Jul",
  august: "Aug",
  september: "Sept",
  october: "Oct",
  november: "Nov",
  december: "Dec",
};

const marketNames = {
  home: "Ev Sahibi",
  away: "Deplasman",
  draw: "Beraberlik",
};

const translations = {
  "under": "Under",
  "over": "Over",
  "home": "Home",
  "away": "Away",
  "draw": "Draw",
  "yes": "Yes",
  "no": "No",
  "1st half": "1st half",
  "2nd half": "2nd half",
  "both teams to score": "Both Teams To Score",
  "to win and both teams to score": "To Win And Both Teams To Score",
  "away/yes": "Away/Yes",
  "away/no": "Away/No",
  "home/yes": "Home/Yes",
  "home/no": "Home/No",
  "total goals": "Total Goals",
  "match prices": "Match Prices",
};

const settings = {
  staticString,
  marketNames,
  translations,
};

export default settings;
