import * as React from "react";
const Delete = (props) => (
  <svg
    id="Trash_icon"
    xmlns="http://www.w3.org/2000/svg"
    width={12.602}
    height={16.802}
    viewBox="0 0 12.602 16.802"
    {...props}
  >
    <path
      id="Path_1880"
      data-name="Path 1880"
      d="M13.342,1.68H10.4V1.26A1.262,1.262,0,0,0,9.141,0H7.461A1.262,1.262,0,0,0,6.2,1.26v.42H3.26A1.262,1.262,0,0,0,2,2.94v.84a1.262,1.262,0,0,0,.84,1.188V15.542A1.262,1.262,0,0,0,4.1,16.8h8.4a1.262,1.262,0,0,0,1.26-1.26V4.968a1.262,1.262,0,0,0,.84-1.188V2.94a1.262,1.262,0,0,0-1.26-1.26Zm-6.3-.42a.42.42,0,0,1,.42-.42h1.68a.42.42,0,0,1,.42.42v.42H7.041Zm5.461,14.7H4.1a.42.42,0,0,1-.42-.42V5.041h9.241v10.5A.42.42,0,0,1,12.5,15.962Zm1.26-12.182a.42.42,0,0,1-.42.42H3.26a.42.42,0,0,1-.42-.42V2.94a.42.42,0,0,1,.42-.42H13.342a.42.42,0,0,1,.42.42Z"
      transform="translate(-2)"
      fill="inherit"
    />
    <path
      id="Path_1881"
      data-name="Path 1881"
      d="M12.42,7a.42.42,0,0,0-.42.42v8.4a.42.42,0,1,0,.84,0V7.42A.42.42,0,0,0,12.42,7Z"
      transform="translate(-3.599 -1.119)"
      fill="inherit"
    />
    <path
      id="Path_1882"
      data-name="Path 1882"
      d="M9.42,7A.42.42,0,0,0,9,7.42v8.4a.42.42,0,1,0,.84,0V7.42A.42.42,0,0,0,9.42,7Z"
      transform="translate(-3.119 -1.119)"
      fill="inherit"
    />
    <path
      id="Path_1883"
      data-name="Path 1883"
      d="M6.42,7A.42.42,0,0,0,6,7.42v8.4a.42.42,0,1,0,.84,0V7.42A.42.42,0,0,0,6.42,7Z"
      transform="translate(-2.64 -1.119)"
      fill="inherit"
    />
  </svg>
);
export default Delete;
